import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component } from 'vue-property-decorator'
import router from '@/bootstrap/router/router'
import Container from 'typedi'
import EventService from '@/modules/common/services/event/event.service'
import Notification from '@/modules/common/services/notification.service'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrTrackView',
  computed: {
    ...mapState('event', ['event']),
    ...mapState('sessions', ['sessions', 'attendanceCounts'])
  }
})

export default class GtrTrackView extends GtrSuper {
  sessions!: Record<string, any>;

  data () {
    return {
      loading: false,
      boxLinks: [
        {
          title: 'Manage Participants',
          description: 'Add & edit participants, export data, and view & add session attendance by participant.',
          icon: 'mdi-account-multiple',
          route: 'level-two.event.attendees', // Track participants do not exist yet
          type: 'icon',
          permission: 'general.participants.view'
        },
        {
          title: 'Manage Sessions',
          description: 'Add & edit sessions, export data, and view session attendance.',
          icon: 'mdi-account-supervisor-circle-outline',
          route: 'level-two.modules.track.sessions',
          type: 'icon',
          permission: 'track.sessions.view'
        },
        {
          title: 'GTR Track Reports',
          description: 'View and export lead attendance tracking reports.',
          icon: 'mdi-view-list',
          route: 'level-two.event.reports', // Track reports do not exist yet
          type: 'icon',
          permission: 'general.reports.view'
        },
        {
          title: 'Support',
          description: 'Need help? Visit our knowledge base or get in touch with us for assistance.',
          image: 'modules/support - grayscale.svg',
          route: 'level-two.modules.support',
          type: 'image',
          fullWidth: true,
          permission: 'general.support.view'
        }
      ],
      trackActivationCode: null
    }
  }

  async mounted () {
    await this.fetchSessions()
    await this.fetchAttendanceCounts()
  }

  async created () {
    await this.getTrackActivationCode()
  }

  private async exportParticipantScans () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('attendee/exportParticipantScans', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async exportSessionScans () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('sessions/exportSessionScans', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async getTrackActivationCode () {
    try {
      this.$data.trackActivationCode = await this.$store.dispatch('event/loadEventCode', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchAttendanceCounts () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('sessions/fetchAttendanceCounts', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get totalScansByParticipant () {
    if (!(this as any).attendanceCounts?.participantsSessionCounts) {
      return 0
    }
    return (this as any).attendanceCounts.participantsSessionCounts.reduce((acc, obj) => acc + obj.count, 0)
  }

  get totalScansBySession () {
    if (!(this as any).attendanceCounts?.sessionsParticipantCounts) {
      return 0
    }
    return (this as any).attendanceCounts.sessionsParticipantCounts.reduce((acc, obj) => acc + obj.count, 0)
  }

  get participantsSessionCountsSorted () {
    if (!(this as any).attendanceCounts?.participantsSessionCounts) {
      return []
    }
    const newParticipantsArr = (this as any).attendanceCounts.participantsSessionCounts.sort((a, b) => b.count - a.count)
    if (newParticipantsArr.length > 3) {
      return newParticipantsArr.slice(0, 3)
    }
    return newParticipantsArr
  }

  get sessionsParticipantCountsSorted () {
    if (!(this as any).attendanceCounts?.sessionsParticipantCounts) {
      return []
    }
    const newSessionArr = (this as any).attendanceCounts.sessionsParticipantCounts.sort((a, b) => b.count - a.count)
    if (newSessionArr.length > 5) {
      return newSessionArr.slice(0, 5)
    }
    return newSessionArr
  }

  get numberOfSessions () {
    return this.sessions?.length
  }

  get uniqueScans () {
    return this.sessions?.reduce((sum: number, session: any) => sum + session.unique_scans, 0)
  }

  private getFirstAndLastChars (name: string): string {
    const nameSplit = name.split(' ')
    if (nameSplit.length <= 1) {
      return ''
    }
    return nameSplit[0].charAt(0) + nameSplit[1].charAt(0)
  }

  private async fetchSessions () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('sessions/fetchSessions', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  navigateTo (link: string) {
    if (!link) {
      return
    }
    const url = `/company/${this.$route.params.uuid}/event/${this.$route.params.event_uuid}/${link}`
    this.$router.push(url)
  }

  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.SESSION_TRACKING.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Track Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }

  progressBarAmountBySession (num: number): number {
    if (this.totalScansBySession === 0) {
      return 15
    }
    const percentageAsInt = (num / this.totalScansBySession) * 100
    if (percentageAsInt < 15) {
      return 15
    }
    return percentageAsInt
  }

  progressBarAmountByParticipant (num: number): number {
    if (this.totalScansByParticipant === 0) {
      return 15
    }
    const percentageAsInt = (num / this.totalScansByParticipant) * 100
    if (percentageAsInt < 15) {
      return 15
    }
    return percentageAsInt
  }
}
